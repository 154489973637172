<script lang="ts">
  import Button from "../form/Button.svelte";
  import SelectDestination from "../search-and-buy/SelectDestination.svelte";

  export let id: string;
  let slug: string | undefined;
</script>

<div class="pt-3 flex flex-col gap-5 max-w-lg">
  <h3 class="font-semibold">Search for a destination</h3>
  <form class="flex flex-row gap-0 sm:gap-4" method="get" action={slug}>
    <div class="flex-grow place-items-stretch">
      <label class="sr-only" for={id}>Destination name</label>
      <SelectDestination
        {id}
        join="right"
        resetable
        on:pick={(e) => (slug = e.detail)} />
    </div>
    <Button type="submit" theme="secondary" join="left">View <span class="max-[420px]:hidden max-sm:text-sm">Factsheet</span></Button>
  </form>
</div>
